export enum CompanySubscriptionTypes {
  Platform = 'platform',

  Learn = 'learn',

  Control = 'control',

  AssetControl = 'asset-control',

  ManagedServiceProvider = 'managed-service-provider',
}